<template>
  <b-card class="blog-edit-wrapper" :title="formTitle">
    <!-- form -->
    <validation-observer ref="eventoRules">
      <b-form class="mt-2">
        <b-row>
          <b-col md="12" class="mb-2">
            <div class="border rounded p-2">
              <h4 class="text-center">
                <b>Foto Portada</b>
              </h4>
              <div class="text-center">
                <b-form-group label="" label-for="">
                  <div class="box-imagen box-imagen1 border rounded">
                    <b-img
                      v-if="imagen"
                      :src="imagen"
                      center
                      fluid
                      thumbnail
                      class="rounded"
                      alt="Card image cap"
                    />
                  </div>
                </b-form-group>
                <b-button
                  v-b-modal.modal-seleccionar-imagen
                  variant="flat-dark"
                  size="sm"
                >
                  <feather-icon icon="ImageIcon" class="mr-50" />
                  <span class="align-middle">Elegir Imagen</span>
                </b-button>
              </div>
            </div>
          </b-col>
        </b-row>
        <b-row>
          <!-- <b-col md="12" class="mb-2">
            <div class="border rounded p-2">
              <h4 class="mb-1 text-center"><b>Foto Portada</b></h4>
              <div class="text-center">
                <b-img
                  v-if="imagen"
                  ref="refPreviewEl"
                  :src="imagen"
                  height="190"
                  width="500"
                  class="rounded mr-2 mb-1 mb-md-0"
                />
                <b-img
                  v-else
                  ref="refPreviewEl"
                  :src="evento.foto_portada"
                  height="190"
                  width="500"
                  class="rounded mr-2 mb-1 mb-md-0"
                />
                <b-form-file
                  ref="refInputEl"
                  v-model="eventoFile"
                  accept=".jpg, .png, .gif"
                  placeholder="Cambiar Fotov"
                  @input="inputImageRenderer"
                />
              </div>
            </div>
          </b-col> -->
          <b-col md="12">
            <b-form-group label-for="titulo" class="mb-2">
              <label for="titulo">Titulo Evento <span class="text-danger">(*)</span></label>
              <validation-provider
                #default="{ errors }"
                name="Titulo evento"
                rules="required"
              >
                <b-form-input
                  id="titulo"
                  v-model="evento.titulo"
                  :state="errors.length > 0 ? false : null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
                <small
                  v-for="error in errores.titulo"
                  :key="error"
                  class="text-danger"
                >{{ error }}</small>
                <!-- <small v-for="error in errores.nombre" :key="error" class="text-danger">{{ error }}</small> -->
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group label="Url" label-for="Url">
              <b-form-input id="url" disabled v-model="evento.url" placeholder="Url" />
            </b-form-group>
          </b-col>
          <b-col md="3">
            <b-form-group
              
              label-for="Fecha Publicacion"
            >
            <label for="Fecha Publicacion">Fecha Publicación <span class="text-danger">(*)</span></label>
              <validation-provider
                #default="{ errors }"
                name="Fecha Publicacion"
                rules="required"
              >
                <flat-pickr
                  v-model="evento.published_at"
                  class="form-control"                  
                  :config="{ enableTime: true, dateFormat: 'Y-m-d H:i', locale: 'es' }"
                />
                <small class="text-danger">{{ errors[0] }}</small>
                <small
                  v-for="error in errores.published_at"
                  :key="error"
                  class="text-danger"
                  >{{ error }}</small
                >
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="3">
            <b-form-group label="Estado" label-for="Estado">
              <v-select
                id="blog-edit-estado"
                v-model="estadoa"
                label="title"
                :disabled="!($hascan('evento.publicar'))"
                :options="estadoOpciones"
                :clearable="false"
              />
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <!-- Descripcion -->
            <b-form-group label="Descripcion" label-for="descripcion">
                           <CkEditorPersonalizado
                ref="ckeditor"
                v-model="evento.descripcion"
                
              />
                <!-- <b-form-textarea
                  id="descripcion"
                  v-model="evento.descripcion"
                  placeholder="Descripcion"
                  rows="3"
                  :state="errors.length > 0 ? false : null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
                <small
                  v-for="error in errores.descripcion"
                  :key="error"
                  class="text-danger"
                  >{{ error }}</small
                > -->
                <!-- <small v-for="error in errores.nombre" :key="error" class="text-danger">{{ error }}</small> -->
              
            </b-form-group>
          </b-col>
          <b-col md="12">
            <b-form-group label-for="">
              <label for="contenido">Contenido <span class="text-danger">(*)</span></label>
              
              <b-button
                v-b-modal.modal-seleccionar-archivo
                variant="flat-dark"
                size="sm"
              >
                <feather-icon icon="ImageIcon" class="mr-50" />
                <span class="align-left">Medios</span>
              </b-button>
              <validation-provider
                #default="{ errors }"
                name="Contenido"
                rules="required"
              >
                <CkEditorPersonalizado
                  ref="ckeditor"
                  v-model="evento.contenido"
                />
                <small class="text-danger">{{ errors[0] }}</small>
                <small
                  v-for="error in errores.contenido"
                  :key="error"
                  class="text-danger"
                  >{{ error }}</small
                >
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="12">
            <h3 class="pt-2">Fecha y Hora del Evento</h3>
          </b-col>
          <b-col md="12">
            <b-form-checkbox
              id="asiento-fiscal"
              v-model="selected"
              value="1"
              unchecked-value="0"
              switch
            >
              <b>Todo el Dia</b>
            </b-form-checkbox>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="6">
            <b-form-group label-for="fecha" class="mb-2">
              <label for="fecha">Fecha <span class="text-danger">(*)</span></label>
              <validation-provider
                #default="{ errors }"
                name="Fecha"
                rules="required"
              >
                <b-form-datepicker
                  id="fecha"
                  v-model="evento.fecha"
                  placeholder="Ingrese la Fecha"
                  local="es"
                  :state="errors.length > 0 ? false : null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
                <small
                  v-for="error in errores.fecha"
                  :key="error"
                  class="text-danger"
                  >{{ error }}</small
                >
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col v-if="selected == 0" md="3">
            <b-form-group
              label="Hora de Inicio"
              label-for="hora_inicio"
              class="mb-2"
            >
              <validation-provider
                #default="{ errors }"
                name="Hora Inicio"
                rules=""
              >
                <b-form-timepicker
                  id="hora_inicio"
                  v-model="evento.hora_inicio"
                  placeholder="ingrese la hora"
                  local="es"
                  :state="errors.length > 0 ? false : null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
                <small
                  v-for="error in errores.hora_inicio"
                  :key="error"
                  class="text-danger"
                  >{{ error }}</small
                >
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col v-if="selected == 0" md="3">
            <b-form-group
              label="Hora de Final"
              label-for="hora_final"
              class="mb-2"
            >
              <validation-provider
                #default="{ errors }"
                name="Hora Final"
                rules=""
              >
                <b-form-timepicker
                  id="hora_final"
                  v-model="evento.hora_final"
                  placeholder="ingrese la hora"
                  local="es"
                  :state="errors.length > 0 ? false : null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
                <small
                  v-for="error in errores.hora_final"
                  :key="error"
                  class="text-danger"
                  >{{ error }}</small
                >
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="12">
            <h3>Detalle del Lugar</h3>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="6">
            <b-form-group
              
              label-for="nombre_lugar"
              class="mb-2"
            >
            <label for="nombre_lugar">Nombre del Lugar <span class="text-danger">(*)</span></label>
              <validation-provider
                #default="{ errors }"
                name="Nombre del lugar"
                rules="required"
              >
                <b-form-input
                  id="nombre_lugar"
                  v-model="evento.nombre_lugar"
                  :state="errors.length > 0 ? false : null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
                <small
                  v-for="error in errores.nombre_lugar"
                  :key="error"
                  class="text-danger"
                  >{{ error }}</small
                >
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group label-for="direccion" class="mb-2">
              <label for="direccion">Direccion <span class="text-danger">(*)</span></label>
              <validation-provider
                #default="{ errors }"
                name="Direccion"
                rules="required"
              >
                <b-form-input
                  id="direccion"
                  v-model="evento.direccion"
                  :state="errors.length > 0 ? false : null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
                <small
                  v-for="error in errores.direccion"
                  :key="error"
                  class="text-danger"
                  >{{ error }}</small
                >
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group label-for="ciudad" class="mb-2">
              <label for="ciudad">Ciudad <span class="text-danger">(*)</span></label>
              <validation-provider
                #default="{ errors }"
                name="Ciudad"
                rules="required"
              >
                <b-form-input
                  id="ciudad"
                  v-model="evento.ciudad"
                  :state="errors.length > 0 ? false : null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
                <small
                  v-for="error in errores.ciudad"
                  :key="error"
                  class="text-danger"
                  >{{ error }}</small
                >
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col md="6">
            <b-form-group label="Telefono" label-for="telefono" class="mb-2">
              <validation-provider
                #default="{ errors }"
                name="Telefono"
                rules=""
              >
                <b-form-input
                  id="telefono"
                  v-model="evento.telefono"
                  :state="errors.length > 0 ? false : null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
                <small
                  v-for="error in errores.telefono"
                  :key="error"
                  class="text-danger"
                  >{{ error }}</small
                >
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col md="6">
            <b-form-group label="Latitud" label-for="latitud" class="mb-2">
              <validation-provider
                #default="{ errors }"
                name="Latitud"
                rules="required"
              >
                <b-form-input
                  id="latitud"
                  v-model="ubicacion.position.lat"
                  :state="errors.length > 0 ? false : null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
                <small
                  v-for="error in errores.latitud"
                  :key="error"
                  class="text-danger"
                  >{{ error }}</small
                >
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group label="Longitud" label-for="longitud" class="mb-2">
              <validation-provider
                #default="{ errors }"
                name="Longitud"
                rules="required"
              >
                <b-form-input
                  id="longitud"
                  v-model="ubicacion.position.lng"
                  :state="errors.length > 0 ? false : null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
                <small
                  v-for="error in errores.longitud"
                  :key="error"
                  class="text-danger"
                  >{{ error }}</small
                >
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col cols="12">
            <div>
              <l-map
                :zoom.sync="zoom"
                :options="mapOptions"
                :center="center"
                style="height: 500px; width: 100%"
              >
                <l-tile-layer :url="url" :attribution="attribution" />
                <l-marker
                  :visible="ubicacion.visible"
                  :draggable="ubicacion.draggable"
                  :lat-lng.sync="ubicacion.position"
                  @click="alert(ubicacion)"
                >
                  <l-popup content="hola" />
                </l-marker>
              </l-map>
            </div>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="12">
            <h3 class="pt-2">Detalles del Organizador</h3>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="6">
            <b-form-group
              label="Nombre del Organizador"
              label-for="nombre_organizador"
              class="mb-2"
            >
              <validation-provider #default="{ errors }" name="Fax" rules="">
                <b-form-input
                  id="nombre_organizador"
                  v-model="evento.nombre_organizador"
                  :state="errors.length > 0 ? false : null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
                <small
                  v-for="error in errores.nombre_organizador"
                  :key="error"
                  class="text-danger"
                  >{{ error }}</small
                >
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group
              label="Telefono"
              label-for="telefono_organizador"
              class="mb-2"
            >
              <validation-provider
                #default="{ errors }"
                name="Telefono Organizador"
                rules=""
              >
                <b-form-input
                  id="telefono_organizador"
                  v-model="evento.telefono_organizador"
                  :state="errors.length > 0 ? false : null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
                <small
                  v-for="error in errores.telefono_organizador"
                  :key="error"
                  class="text-danger"
                  >{{ error }}</small
                >
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="12">
            <h3>Redes Sociales</h3>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="6">
            <b-form-group
              label="URL Facebook"
              label-for="url_facebook"
              class="mb-2"
            >
              <validation-provider
                #default="{ errors }"
                name="URL Facebook"
                rules="url"
              >
                <b-form-input
                  id="url_facebook"
                  v-model="evento.url_facebook"
                  :state="errors.length > 0 ? false : null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
                <small
                  v-for="error in errores.url_facebook"
                  :key="error"
                  class="text-danger"
                  >{{ error }}</small
                >
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group
              label="URL Instagram"
              label-for="url_instagram"
              class="mb-2"
            >
              <validation-provider
                #default="{ errors }"
                name="URL Instagram"
                rules="url"
              >
                <b-form-input
                  id="url_instagram"
                  v-model="evento.url_instagram"
                  :state="errors.length > 0 ? false : null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
                <small
                  v-for="error in errores.url_instagram"
                  :key="error"
                  class="text-danger"
                  >{{ error }}</small
                >
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group
              label="URL Youtube"
              label-for="url_youtube"
              class="mb-2"
            >
              <validation-provider
                #default="{ errors }"
                name="URL Youtube"
                rules="url"
              >
                <b-form-input
                  id="url_youtube"
                  v-model="evento.url_youtube"
                  :state="errors.length > 0 ? false : null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
                <small
                  v-for="error in errores.url_youtube"
                  :key="error"
                  class="text-danger"
                  >{{ error }}</small
                >
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="12">
            <h3 class="pt-2">Formulario</h3>
          </b-col>
          <b-col md="12">
            <b-form-checkbox
              id="formulario"
              v-model="selectedFormulario"
              value="1"
              unchecked-value="0"
              switch
            >
              <b>Formulario de Inscripcion</b>
            </b-form-checkbox>
          </b-col>
        </b-row>
        <b-row v-if="selectedFormulario == 1">
          <b-col md="6">
            <b-form-group
              label="URL Formulario"
              label-for="url_formulario"
              class="mb-2"
            >
              <validation-provider
                #default="{ errors }"
                name="URL Formulario"
                rules="url"
              >
                <b-form-input
                  id="url_formulario"
                  v-model="evento.url_formulario"
                  :state="errors.length > 0 ? false : null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
                <small
                  v-for="error in errores.url_formulario"
                  :key="error"
                  class="text-danger"
                  >{{ error }}</small
                >
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12" class="mt-50">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-1"
              @click="validar"
            >
              Guardar
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              variant="outline-secondary"
              :to="{ name: 'eventos' }"
            >
              Cancelar
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
    <b-row />

    <!--/ form -->
    <!--Componente Medios -->
    <MediosComponent @enviarMedia="recibirMedia"></MediosComponent>
    <!--Componente Imagen -->
    <ImagenComponent @enviarImagen="recibirImagen"></ImagenComponent>
  </b-card>
</template>

<script>
import axios from "@axios";
import {
  required,
  url,
  between,
  alpha,
  integer,
  password,
  min,
  digits,
  alphaDash,
  length,
} from "@validations";
import { ValidationProvider, ValidationObserver, localize } from "vee-validate";
import es from "vee-validate/dist/locale/es.json";
import {
  BFormCheckbox,
  BCard,
  BMedia,
  BAvatar,
  BCardText,
  BMediaAside,
  BMediaBody,
  BForm,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BImg,
  BFormFile,
  BLink,
  BButton,
  BFormTimepicker,
  BFormDatepicker,
  BFormTextarea,
} from "bootstrap-vue";

import vSelect from "vue-select";
import Ripple from "vue-ripple-directive";
import { useInputImageRenderer } from "@core/comp-functions/forms/form-utils";
import { ref } from "@vue/composition-api";
import {
  LMap,
  LTileLayer,
  LMarker,
  LPolyline,
  LLayerGroup,
  LTooltip,
  LPopup,
  LControlZoom,
  LControlAttribution,
  LControlScale,
  LControlLayers,
} from "vue2-leaflet";
import { latLngBounds, Icon } from "leaflet";
import CkEditorPersonalizado from "@/componentes/CkEditorPersonalizado";
import MediosComponent from "@/componentes/MediosComponent.vue";
import ImagenComponent from "@/componentes/ImagenComponent.vue";
import flatPickr from 'vue-flatpickr-component'
import router from "@/router";
import "leaflet/dist/leaflet.css";

const Spanish = require('flatpickr/dist/l10n/es');
localize('es', es);

delete Icon.Default.prototype._getIconUrl;
Icon.Default.mergeOptions({
  iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
  iconUrl: require("leaflet/dist/images/marker-icon.png"),
  shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
});

export default {
  components: {
    BFormCheckbox,
    BCard,
    BMedia,
    BAvatar,
    BCardText,
    BMediaAside,
    BMediaBody,
    BForm,
    BLink,
    BImg,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormInput,
    BFormFile,
    vSelect,
    LMap,
    LTileLayer,
    LMarker,
    LPolyline,
    LLayerGroup,
    LTooltip,
    LPopup,
    LControlZoom,
    LControlAttribution,
    LControlScale,
    LControlLayers,
    ValidationProvider,
    ValidationObserver,
    CkEditorPersonalizado,
    BFormTimepicker,
    BFormDatepicker,
    BFormTextarea,
    MediosComponent,
    ImagenComponent,
    flatPickr,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      required,

      min,
      errores: [],

      selectedForm: [],
      optionsForm: [
        { text: "Nombre", value: "isFormNombre" },
        { text: "CI/Documento Identificacion", value: "isFormCi" },
        { text: "Profesion", value: "isFormProfesion" },
        { text: "Pais", value: "isFormPais" },
        { text: "Departamento", value: "isFormDepartamento" },
        { text: "Ciudad", value: "isFormCiudad" },
        { text: "Telefono", value: "isFormTelefono" },
        { text: "Email", value: "isFormEmail" },
      ],
      estadoOpciones:[
        { id: 1, title: 'Publicado' },
        { id: 2, title: 'Borrador' },
        { id: 3, title: 'Papelera' }
      ],
      estadoa: { id: 2, title: 'Borrador' },

      evento: {
        nombre: "",
        direccion: "",
        telefono: "",
        telefono_organizador: "",
        url_facebook: "",
        url_instagram: "",
        url_youtube: "",
        fax: "",
        hora_inicio: "",
        hora_final: "",
      },
      eventoFile: null,
      imagenMiniatura: "",
      eventoOption: [],

      ubicacion: {
        position: { lat: -19.062117883514652, lng: -65.25878906250001 },
        visible: true,
        draggable: true,
      },
      selected: "0",
      selectedFormulario: "0",
      zoom: 13,
      center: [-19.062117883514652, -65.25878906250001],
      mapOptions: {
        zoomControl: true,
        attributionControl: true,
        zoomSnap: true,
      },
      url: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
      attribution:
        '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors',
    };
  },
  computed: {
    formTitle() {
      return this.evento.entrada_id ? "Editar Evento" : "Nueva Evento";
    },
  },
  watch: {
    "evento.nombre": function () {
      this.errores.nombre = [];
    },
    "evento.nombre_fiscal": function () {
      this.errores.nombre_fiscal = [];
    },
    "evento.direccion": function () {
      this.errores.direccion = [];
    },
    "evento.telefono": function () {
      this.errores.telefono = [];
    },
    "evento.fax": function () {
      this.errores.fax = [];
    },
    "evento.ubicacion": function () {
      this.errores.ubicacion = [];
    },
    "ubicacion.position.lat": function () {
      this.errores.latitud = [];
    },
    "ubicacion.position.lng": function () {
      this.errores.longitud = [];
    },
  },
  created() {
    this.date();
    
    if (router.currentRoute.params.id) {
      axios
        .get(`/eventos/${router.currentRoute.params.id}`)
        .then((response) => {
          this.evento = response.data.data;
          this.ubicacion.position.lat = this.evento.latitud;
          this.ubicacion.position.lng = this.evento.longitud;
          this.center = [this.evento.latitud, this.evento.longitud];
          this.evento.titulo=this.evento.entrada.titulo;
          this.evento.url=this.evento.entrada.url;
          this.imagen=this.evento.entrada.imagen;
          this.evento.published_at=this.evento.entrada.published_at;
          this.evento.descripcion=this.evento.entrada.descripcion;
          this.evento.contenido=this.evento.entrada.contenido;
          this.estadoa.id=this.evento.entrada.estado;
          this.selected= this.evento.hora_inicio == "" ? "1":"0";
          this.selectedFormulario= this.evento.url_formulario == "" ? "0":"1";
          const valor=this.estadoOpciones.find(estado => estado.id === this.evento.entrada.estado);
          this.estadoa={id:valor.id,title:valor.title};
        })
        .catch((error) => console.error(error));
    }
  },
  setup() {
    const refInputEl = ref(null);
    const refPreviewEl = ref(null);
    const imagen = ref("");

    const { inputImageRenderer } = useInputImageRenderer(
      refInputEl,
      (base64) => {
        refPreviewEl.value.src = base64;
        imagen.value = base64;
      }
    );

    return {
      refInputEl,
      refPreviewEl,
      inputImageRenderer,
      imagen,
    };
  },

  methods: {
    
            date() {
      const hoy = new Date();
      var date =
        hoy.getFullYear() + "-" + (hoy.getMonth() + 1) + "-" + hoy.getDate();
      var time = hoy.getHours() + ":" + hoy.getMinutes();
      this.evento.published_at = date + " " + time;
    },
    async success(Mensaje) {
      await this.$swal({
        title: Mensaje,

        icon: "success",
        timer: 2000,
        confirmButtonText: "Aceptar",
        customClass: {
          confirmButton: "btn btn-primary",
        },
        buttonsStyling: false,
      });
      router.push({ name: "eventos" });
    },
    async getAllEventos() {
      await axios
        .get("/getAlleventosSelect")
        .then((response) => {
          this.eventoOption = response.data.data;
        })
        .catch((error) => reject(error));
    },

    traereventos() {
      if (this.selected == "1") this.getAlleventos();
    },

    alert(item) {
      // eslint-disable-next-line no-alert
      alert(`this is ${JSON.stringify(item)}`);
    },
    validar() {
      this.$refs.eventoRules.validate().then((success) => {
        if (success) {
          this.guardar();
        }
      });
    },
    async guardar() {
      this.evento.imagen = this.imagen;
      this.evento.latitud = this.ubicacion.position.lat;
      this.evento.longitud = this.ubicacion.position.lng;
      this.evento.estado=this.estadoa.id;

      if (this.selectedFormulario == "1") {
       // console.log(this.selectedForm);
      }
      if (this.evento.entrada_id) {
        //formData.append("_method", "PUT");
        axios
          .put(`/eventos/${this.evento.entrada_id}`, this.evento)
          .then((response) => {
            this.success("Actualizado con Exito");
          })
          .catch((error) => {
            if (error.response.status === 422) {
              this.errores = error.response.data.errors;
            } else {
              console.error(error);
            }
          });
      } else {
        axios
          .post("/eventos", this.evento)
          .then((response) => {
            this.success("Insertado con Exito");
            // router.push({ name: 'eventos' });
          })
          .catch((error) => {
            if (error.response.status === 422) {
              this.errores = error.response.data.errors;
            } else {
              console.error(error);
            }
          });
      }
    },

    recibirMedia(media) {
      if (media.tipo == "IMAGEN") {
        //this.$refs.ckeditor.editorCk
        const content = `<figure class="image"><img src="${media.url}"></figure>`;
        const viewFragment =
          this.$refs.ckeditor.editorCk.data.processor.toView(content);
        const modelFragment =
          this.$refs.ckeditor.editorCk.data.toModel(viewFragment);
        this.$refs.ckeditor.editorCk.model.insertContent(
          modelFragment,
          this.$refs.ckeditor.editorCk.model.document.selection.getFirstPosition()
        );
      } else if (media.tipo == "VIDEO") {
        if (media.enlace) {
          const content = `<figure class="media"><oembed url="${media.url}"></oembed></figure>`;
          const viewFragment = this.$refs.ckeditor.editorCk.data.processor.toView(content);
          const modelFragment = this.$refs.ckeditor.editorCk.data.toModel(viewFragment);
          this.$refs.ckeditor.editorCk.model.insertContent(modelFragment,
            this.$refs.ckeditor.editorCk.model.document.selection.getFirstPosition()
          );
        }else{
          const content =`
            <div class="raw-html-embed" style="text-align:center;">
              <video width="540" controls>
                <source src="${media.url}" type="video/mp4">
              </video>
            </div>`;
          const viewFragment =
          this.$refs.ckeditor.editorCk.data.processor.toView(content);
          const modelFragment = this.$refs.ckeditor.editorCk.data.toModel(viewFragment);
          this.$refs.ckeditor.editorCk.model.insertContent(modelFragment,this.$refs.ckeditor.editorCk.model.document.selection.getFirstPosition());
        }
      } else if(media.tipo=='AUDIO'){
        if (media.enlace) {
           const content =` 
            <div class="raw-html-embed" style="text-align:center;">
              <iframe src="${media.url}" width="50%" allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"></iframe>
            </div>`;
          const viewFragment = this.$refs.ckeditor.editorCk.data.processor.toView(content);
          const modelFragment = this.$refs.ckeditor.editorCk.data.toModel(viewFragment);
          this.$refs.ckeditor.editorCk.model.insertContent(modelFragment,
            this.$refs.ckeditor.editorCk.model.document.selection.getFirstPosition()
          );
        }else{
          const content =` 
            <div class="raw-html-embed" style="text-align:center;">
              <audio controls="">
                <source src="${media.url}" type="audio/mpeg">
              </audio>
            </div>`;
          const viewFragment =
          this.$refs.ckeditor.editorCk.data.processor.toView(content);
          const modelFragment = this.$refs.ckeditor.editorCk.data.toModel(viewFragment);
          this.$refs.ckeditor.editorCk.model.insertContent(modelFragment,this.$refs.ckeditor.editorCk.model.document.selection.getFirstPosition());
        }
      } else if(media.tipo=='DOCUMENTO'){
        const content =` 
          <div class="raw-html-embed" style="text-align:center;">
            <iframe src="${media.url}" width="80%" height="720px"></iframe>
          </div>`;
        const viewFragment =
        this.$refs.ckeditor.editorCk.data.processor.toView(content);
        const modelFragment = this.$refs.ckeditor.editorCk.data.toModel(viewFragment);
        this.$refs.ckeditor.editorCk.model.insertContent(modelFragment,this.$refs.ckeditor.editorCk.model.document.selection.getFirstPosition());
      }else{
        const content = `<a href="${media.url}">${media.titulo}</a>`;
        const viewFragment =
          this.$refs.ckeditor.editorCk.data.processor.toView(content);
        const modelFragment =
          this.$refs.ckeditor.editorCk.data.toModel(viewFragment);
        this.$refs.ckeditor.editorCk.model.insertContent(
          modelFragment,
          this.$refs.ckeditor.editorCk.model.document.selection.getFirstPosition()
        );
      }
    },
    // RECIBIR IMAGEN DEL COMPONENTE
    recibirImagen(imagen) {
      this.imagen = imagen.url;
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/quill.scss";
@import "@core/scss/vue/pages/page-blog.scss";
@import "@core/scss/vue/libs/map-leaflet.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
.custom-file-input:lang(es) ~ .custom-file-label::after {
  content: "Buscar";
}

.vue2leaflet-map {
  &.leaflet-container {
    height: 350px;
  }
}

.box-imagen {
  height: 13rem;
}
.box-imagen img {
  width: 100%;
  height: 100%;
}
.box-imagen1 img {
  object-fit: contain;
}
.add-new-client-header {
  padding: $options-padding-y $options-padding-x;
  color: $success;

  &:hover {
    background-color: rgba($success, 0.12);
  }
}
.vs__open-indicator {
  fill: rgba(60, 60, 60, 0.5);
}
</style>
